<div class="main">
  <div class="header" #header>
    <div class="logo">
      <h4 class="heading-4">m.</h4>
    </div>
    <div class="nav">
      <div class="burger-icon"></div>
    </div>
  </div>
  <div class="center-info">
    <div class="center-info__box" #centerBox>
      <div class="center-info__box--left">Sort by <span>Latest</span></div>
      <div class="center-info__box--right">2020</div>
    </div>
  </div>
  <section class="section-one" #sectionOne>
    <figure class="image-container one">
      <img src="./assets/1.jpg" alt="image 1" />
      <figcaption class="caption">21</figcaption>
    </figure>
    <figure class="image-container">
      <img src="./assets/2.jpg" alt="image 2" #imageSecond />
      <figcaption class="caption" #imageSecondCap>Flower</figcaption>
    </figure>
    <figure class="image-container" #imageThird>
      <div class="fig">Sort Of</div>
      <figcaption class="caption">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate
        deserunt dolore fugiat maxime nam eos debitis sint culpa perferendis.
      </figcaption>
    </figure>
    <figure class="image-container">
      <figcaption class="caption" #imageFourthCap>
        '20
      </figcaption>
      <img src="./assets/3.jpg" alt="image 2" #imageFourth />
      <figcaption class="caption">
        Glossary
      </figcaption>
    </figure>
    <figure class="image-container" #imageFifth>
      <img src="./assets/4.jpg" alt="image 2" />
    </figure>
  </section>
  <section class="section-two">
    <div class="letter" #letter>
      <span>A</span>
      <span>(2 Items)</span>
    </div>
    <div class="titles" #titles>
      <div class="title-1">
        <span class="num">01</span>
        <h1>Alstroemeria</h1>
      </div>
      <div class="title-1">
        <span class="num">02</span>
        <h1>Amaryllis</h1>
      </div>
    </div>
    <div class="paragraph" #paragraph>
      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Adipisci ex ab
      numquam amet, voluptate suscipit temporibus, quaerat unde magnam
      perspiciatis error! Quod vero eligendi a quidem suscipit dolorem quo
      natus?
    </div>
    <figure class="image-1" #imgOne>
      <img src="./assets/5.jpg" alt="image 1" />
    </figure>
    <figure class="image-2" #imgTwo>
      <img src="./assets/6.jpg" alt="image 2" />
    </figure>
  </section>
</div>
